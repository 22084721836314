import * as React from 'react'
import { OptionsType } from 'react-select'

interface ILanguageListContext {
  languages: OptionsType
}

const languages: OptionsType = []

export const LanguageOptionsListContext = React.createContext<ILanguageListContext>({
  languages,
})
