import * as React from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

const primaryColors = {
  light: '#161f90',
  main: '#526483',
  dark: '#22345f',
}

export const secondaryColors = {
  main: '#d2d2d2',
  light: '#fcfcfc',
  dark: '#9a9a9a',
}

export const alertColor = '#e14b4b'

export const primaryTextColor = '#26252a'

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    submitButton: {
      backgroundColor: string
      color: string
      hover: {
        backgroundColor: string
        color: string
      }
    }
    field: {
      size: number
      borderColor: string
      placeholderColor: string
      defaultWidth: number
    }
    logo: {
      width: number
    }
    global: {
      space: number
    }
    appBar: {
      height: number
    }
    menu: {
      width: number
      height: number
    }
    linkColor: {
      color: string
    }
    redBtnTextColor: {
      color: string
    }
    fieldError: {
      color: string
    }
    statusComplete: {
      color: string
    }
    statusIncomplete: {
      color: string
    }
    statusProblem: {
      color: string
    }
    statusRefunded: {
      color: string
    }
    editFieldData: {}
    editFieldControls: {
      primaryColor: string
      secondaryColor: string
    }
    button: {
      error: {
        color: string
      }
    }
    approveCheck: {
      active: {
        color: string
      }
      inactive: {
        color: string
      }
    }
    programStatus: {
      completed: {
        color: string
      }
      notCompleted: {
        color: string
      }
    }
    tooltipColor: {
      color: string
    }
    sortArrowColor: {
      color: string
    }

    primaryTextColor: {
      color: string
    }
    borderStyle: {
      border: string
    }
    activeGreen: {
      main: string
      dark: string
    }
    activeRed: {
      main: string
      dark: string
    }
    commonTextButtons: {
      addButton: {
        color: string
        cursor: string
      }
      deleteButton: {
        color: string
        cursor: string
      }
    }
  }
  // allow configuration using `createMuiTheme`

  interface ThemeOptions {
    submitButton: {
      backgroundColor: string
      color: string
      hover: {
        backgroundColor: string
        color: string
      }
    }
    field: {
      size: number
      borderColor: string
      placeholderColor: string
      defaultWidth: number
    }
    logo: {
      width: number
    }
    global: {
      space: number
    }
    appBar: {
      height: number
    }
    menu: {
      width: number
      height: number
    }
    linkColor: {
      color: string
    }
    redBtnTextColor: {
      color: string
    }
    fieldError: {
      color: string
    }
    statusComplete: {
      color: string
    }
    statusIncomplete: {
      color: string
    }
    statusProblem: {
      color: string
    }
    statusRefunded: {
      color: string
    }
    editFieldData: {}
    editFieldControls: {
      primaryColor: string
      secondaryColor: string
    }
    button: {
      error: {
        color: string
      }
    }
    approveCheck: {
      active: {
        color: string
      }
      inactive: {
        color: string
      }
    }
    programStatus: {
      completed: {
        color: string
      }
      notCompleted: {
        color: string
      }
    }
    tooltipColor: {
      color: string
    }
    sortArrowColor: {
      color: string
    }
    primaryTextColor: {
      color: string
    }
    borderStyle: {
      border: string
    }
    activeGreen: {
      main: string
      dark: string
    }
    activeRed: {
      main: string
      dark: string
    }
    commonTextButtons: {
      addButton: {
        color: string
        cursor: string
      }
      deleteButton: {
        color: string
        cursor: string
      }
    }
  }
}

const primaryFont = ['Montserrat', 'sans-serif'].join(',')
// All the following keys are optional.
// We try our best to provide a great default value.
export const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: primaryFont,
        color: primaryTextColor,
        margin: 0,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        height: 40,
        fontSize: 12,
        minWidth: 150,
      },
      contained: {
        fontSize: 15,
      },
      outlined: {
        color: '#b3b3b3',
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: 3,
        fontSize: 11,
      },
    },
    MuiLink: {
      root: {
        fontFamily: primaryFont,
        color: '#335185',
        fontSize: 11,
        cursor: 'pointer',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#526483',
        borderRadius: '2px',
      },
    },
    MuiTableCell: {
      root: {
        paddingRight: 5,
      },
    },
    MuiTableHead: {
      root: {
        textTransform: 'uppercase',
        background: secondaryColors.light,
      },
    },
    MuiCircularProgress: {
      root: {
        marginTop: '15%',
        marginLeft: '50%',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#26252a4c',
      },
    },
    MuiDialog: {
      paper: {
        overflowY: 'visible',
      },
    },
  },
  palette: {
    primary: primaryColors,
    secondary: secondaryColors,
    background: {},
  },
  typography: {
    fontFamily: primaryFont,
  },
  submitButton: {
    backgroundColor: '#526483',
    color: 'white',
    hover: {
      backgroundColor: '#325185',
      color: 'white',
    },
  },
  field: {
    size: 40,
    borderColor: '#c4c4c4',
    placeholderColor: '#929294',
    defaultWidth: 400,
  },
  logo: {
    width: 60,
  },
  global: {
    space: 80,
  },
  appBar: {
    height: 80,
  },
  menu: {
    width: 220,
    height: 68,
  },
  redBtnTextColor: {
    color: '#e14b4b',
  },
  linkColor: {
    color: '#335185',
  },
  fieldError: {
    color: '#ff8686',
  },
  statusComplete: {
    color: '#62e61c',
  },
  statusIncomplete: {
    color: '#ff7b7b',
  },
  statusProblem: {
    color: '#ffd465',
  },
  statusRefunded: {
    color: 'purple',
  },
  editFieldData: {
    backgroundColor: '#fcfcfc',
    padding: '10px 15px 10px 15px',
    borderRadius: 4,
    fontWeight: 'inherit',
    fontSize: 14,
    minHeight: 20,
    overflowWrap: 'break-word',
  },
  editFieldControls: {
    primaryColor: '#0d2c92',
    secondaryColor: '#b3b3b3',
  },
  button: {
    error: {
      color: alertColor,
    },
  },
  approveCheck: {
    active: {
      color: '#146f10',
    },
    inactive: {
      color: '#7c7c7c',
    },
  },
  programStatus: {
    completed: {
      color: '#e3ffcd',
    },
    notCompleted: {
      color: '#ffe3e3',
    },
  },
  tooltipColor: {
    color: '#526483',
  },
  sortArrowColor: {
    color: '#2d394d',
  },
  primaryTextColor: {
    color: primaryTextColor,
  },
  borderStyle: {
    border: '0.5px solid rgba(38, 37, 42, 0.3)',
  },
  activeGreen: {
    main: '#50bf45',
    dark: '#00680a',
  },
  activeRed: {
    main: '#ff0000',
    dark: '#a81616',
  },
  commonTextButtons: {
    addButton: {
      color: primaryColors.main,
      cursor: 'pointer',
    },
    deleteButton: {
      color: alertColor,
      cursor: 'pointer',
    },
  },
})

//use this type instead of default Theme to use extended props
export type Theme = typeof theme

const withTheme = <P extends object>(Component: React.ComponentType<P>) =>
  class withTheme extends React.Component<P> {
    render() {
      return (
        <MuiThemeProvider theme={theme}>
          <Component {...this.props} />
        </MuiThemeProvider>
      )
    }
  }

export default withTheme
