import * as React from 'react'
import AppBar from '@material-ui/core/AppBar'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import Logo from '../icons/logo'
import NavOptions from './navOptions'
import useRouter from 'use-react-router'
import { baseEresUrl } from '../utils/getValues'
import SaveDataDialog from '../components/application/saveDataDialog'
import { useStoreState } from '../store'
import { deleteApplication } from '../services/applicationService'

const styles = (theme: Theme) =>
  createStyles({
    logoContainer: {
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      marginLeft: 40,
      cursor: 'pointer',
      '@media screen and (max-width: 991px)': {
        marginLeft: 20,
      },
      '@media screen and (max-width: 575px)': {
        marginLeft: 5,
        paddingLeft: 15,
      },
    },
    appBar: {
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'row',
      height: 80,
      borderBottom: '2px solid #ededed',
      position: 'fixed',
      width: '100%',
    },
    optionsContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: 40,
      '@media screen and (max-width: 991px)': {
        marginRight: 20,
      },
      '@media screen and (max-width: 575px)': {
        marginRight: 0,
      },
    },
  })

interface INavBarProps extends WithStyles<typeof styles> {}

const NavBar: React.SFC<INavBarProps> = (props) => {
  const { history } = useRouter()

  const [open, setOpen] = React.useState<boolean>(false)
  const currentUser = useStoreState((state) => state.user.current)
  const deleteApp = (id) => {
    if (id) {
      deleteApplication(Number(id)).then(() => {
        history.push('/dashboard')
      })
    }
    setOpen(false)
  }
  const appId = useStoreState((state) => state.applicationIdStore.applicationStoreId)
  const goHome = React.useCallback(() => {
    if (
      history.location.pathname === '/eres-sign-in' ||
      history.location.pathname === '/sign-in' ||
      history.location.pathname === '/agent-sign-in' ||
      history.location.pathname === '/create-account'
    ) {
      window.location.href = `${baseEresUrl}`
    } else if (history.location.pathname === '/application') {
      if (appId) {
        ;['client', 'agent']?.includes(currentUser.role) ? setOpen(true) : deleteApp(appId)
      } else {
        history.push('/dashboard')
      }
    } else if (history.location.pathname !== '/dashboard') {
      history.push('/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, appId])

  const { classes } = props
  return (
    <>
      <AppBar elevation={0} className={classes.appBar} position="sticky">
        <div className={classes.logoContainer} onClick={goHome}>
          <Logo />
        </div>

        <div className={classes.optionsContainer}>
          <NavOptions />
        </div>
      </AppBar>
      {open && <SaveDataDialog open={open} setOpen={setOpen} />}
    </>
  )
}
const StyledNavBar = withStyles(styles)(NavBar)
export { StyledNavBar as NavBar }
export default StyledNavBar
