import { INotification } from '../model/Notification'
import { Action, action } from 'easy-peasy'

export interface INotificationsState {
  current?: INotification[]

  setCurrent: Action<INotificationsState, INotification[]>
  removeCurrent: Action<INotificationsState>
}

export const notificationsState: INotificationsState = {
  current: undefined,
  setCurrent: action((state, payload) => {
    state.current = payload
  }),
  removeCurrent: action(state => {
    state.current = undefined
  }),
}
