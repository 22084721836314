import { IUser } from '../model/User'
import { Action, action } from 'easy-peasy'

export interface IUserState {
  current?: IUser

  setCurrent: Action<IUserState, IUser>
  removeCurrent: Action<IUserState>
}

export const userState: IUserState = {
  current: undefined,
  setCurrent: action((state, payload) => {
    state.current = payload
  }),
  removeCurrent: action((state) => {
    state.current = undefined
  }),
}
