import * as React from 'react'
import { OptionsType } from 'react-select'

interface ICountryListContext {
  countries: OptionsType
}

const countries: OptionsType = []

export const CountryOptionsListContext = React.createContext<ICountryListContext>({
  countries,
})
