import * as React from 'react'
import { tooltipStyles } from '../../../utils/tooltipUtils'
import { Tooltip } from '@material-ui/core'

interface IEresTooltipProps {
  children: React.ReactElement
  title: React.ReactElement
  onOpen?: () => void
}

const EresTooltip = (props: IEresTooltipProps) => {
  const { arrow, ...tooltipClasses } = tooltipStyles()
  const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(null)

  return (
    <Tooltip
      interactive
      classes={tooltipClasses}
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef,
            },
          },
        },
      }}
      {...props}
      title={
        <React.Fragment>
          <span className={arrow} ref={setArrowRef} />
          {props.title}
        </React.Fragment>
      }
    />
  )
}

export default EresTooltip
