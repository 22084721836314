export type PurposeGeneralType = 'Education' | 'Professional Licensure' | 'General Purpose' | 'Pre-Evaluation'

export interface IPurposeItem {
  id: number
  active: boolean
  value: PurposeGeneralType
}

export interface IPurposeItemValues {
  id: number
  title: React.ReactNode
  icon: React.ReactNode
}

export interface IPurposeListItem {
  value: PurposeType
  active: boolean
  subsection: boolean
}

export type PurposeType = keyof IPurposeList

export interface IPurposeList {
  high_school_placement: string
  higher_education: string
  nursing: string
  cpa: string
  acupuncture: string
  teaching_credential: string
  speech: string
  bar_exam: string
  architecture: string
  dentistry: string
  medical_doctor: string
  real_estate: string
  barbering_cosmetology: string
  professional_engineer: string
  abih: string
  cma: string
  psychologist_certification: string
  nursing_cas: string
  employment_or_promotion: string
  immigration: string
  military_enlistment: string
  for_future_reference_gen: string
  for_future_reference: string
  other: string
}

export const purposeList: IPurposeList = {
  high_school_placement: 'High School Placement',
  higher_education: 'Higher Education',
  nursing: 'Nursing License',
  cpa: 'CPA License',
  acupuncture: 'Acupuncture License',
  teaching_credential: 'Teaching Credential',
  speech: 'Speech Language Pathology License',
  employment_or_promotion: 'Employment or Promotion',
  bar_exam: 'Bar Exam',
  architecture: 'Architecture',
  dentistry: 'Dentistry',
  medical_doctor: 'Medical Doctor',
  real_estate: 'Real Estate',
  barbering_cosmetology: 'Barbering Cosmetology',
  professional_engineer: 'Professional Engineer',
  abih: 'ABIH',
  cma: 'CMA',
  psychologist_certification: 'Psychologist Certification',
  nursing_cas: 'Nursing Cas',
  immigration: 'Immigration',
  military_enlistment: 'Military Enlistment',
  for_future_reference_gen: 'For Future Reference (Gen)',
  for_future_reference: 'For Future Reference',
  other: 'Other',
}
export const purposeListCodes: IPurposeList = {
  high_school_placement: 'HSP',
  higher_education: 'HE',
  nursing: 'Nursing',
  cpa: 'CPA',
  acupuncture: 'Acupuncture',
  teaching_credential: 'TC',
  speech: 'SPL',
  employment_or_promotion: 'EM',
  bar_exam: 'BAR',
  architecture: 'ARCH',
  dentistry: 'DEN',
  medical_doctor: 'MD',
  real_estate: 'RE',
  barbering_cosmetology: 'BC',
  professional_engineer: 'PE',
  abih: 'ABIH',
  cma: 'CMA',
  psychologist_certification: 'PC',
  nursing_cas: 'Nursing Cas',
  immigration: 'IM',
  military_enlistment: 'Military Enlistment',
  for_future_reference_gen: 'For Future Reference (Gen)',
  for_future_reference: 'For Future Reference',
  other: 'FR',
}