import * as React from 'react'

interface BellIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number
  isActive?: boolean //this bell icon can be active or not have the red dot. activate it by setting isActive
}

const BellIcon = ({ size, isActive = false }: BellIconProps) => (
  <svg width={size ? size : 20} height={size ? size : 24} viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" fill="none" fillRule="evenodd">
      <g id="icons/bell">
        <g id="noun_Bell_972382">
          <g id="Group">
            <path
              d="M18.089,21 L1.911,21 C1.175,21 0.519,20.588 0.2,19.924 C-0.119,19.26 -0.031,18.489 0.429,17.914 L3,14.938 L3,8.5 C3,5.234 5.336,2.372 8.5,1.671 L8.5,1.5 C8.5,0.673 9.173,0 10,0 C10.827,0 11.5,0.673 11.5,1.5 L11.5,1.671 C14.664,2.372 17,5.234 17,8.5 L17,14.938 L19.56,17.899 C20.033,18.49 20.121,19.259 19.801,19.923 C19.481,20.587 18.825,21 18.089,21 Z M10,1 C9.724,1 9.5,1.224 9.5,1.5 L9.5,2.085 L9.077,2.58 C6.183,3.033 4,5.578 4,8.5 L4,15.125 L3.878,15.452 L1.196,18.554 C0.987,18.816 0.946,19.171 1.1,19.491 C1.254,19.811 1.557,20 1.911,20 L18.089,20 C18.443,20 18.746,19.81 18.9,19.49 C19.054,19.17 19.013,18.815 18.792,18.539 L16.123,15.452 L16,15.125 L16,8.5 C16,5.578 13.817,3.033 10.923,2.58 L10.5,2.085 L10.5,1.5 C10.5,1.224 10.276,1 10,1 Z"
              id="Shape"
              fill="#26252A"
              fillRule="nonzero"
              opacity="0.8"
            />
            <path
              d="M9.969,24 C8.315,24 6.969,22.654 6.969,21 L6.969,20.5 L7.969,20.5 L7.969,21 C7.969,22.103 8.866,23 9.969,23 C11.072,23 11.969,22.103 11.969,21 L11.969,20.5 L12.969,20.5 L12.969,21 C12.969,22.654 11.623,24 9.969,24 Z"
              id="Path"
              fill="#26252A"
              fillRule="nonzero"
              opacity="0.8"
            />
            <rect id="Rectangle" fill="#26252A" fillRule="nonzero" opacity="0.8" x="12" y="14" width="4" height="1" />
            {isActive && <circle id="Oval" fill="#FF5A5A" cx="16" cy="4" r="4" />}
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default BellIcon
