import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router'
import { CircularProgress } from '@material-ui/core'
const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'))
const SignUp = lazy(() => import('./pages/authentication/signUp'))
const SignIn = lazy(() => import('./pages/authentication/signIn'))
const AgentSignIn = lazy(() => import('./pages/authentication/agentSignIn'))
const ClientSignIn = lazy(() => import('./pages/authentication/clientSignIn'))
const ForgotPassword = lazy(() => import('./pages/authentication/forgotPassword'))
const AgentForgotPassword = lazy(() => import('./pages/authentication/agentForgotPassword'))
const ResetPassword = lazy(() => import('./pages/authentication/resetPassword'))
const Profile = lazy(() => import('./pages/profile'))
const NotificationsPage = lazy(() => import('./pages/notifications'))
const Application = lazy(() => import('./pages/application'))
const AdditionalServices = lazy(() => import('./pages/admin/additionalServices'))
const ServicePrices = lazy(() => import('./pages/admin/servicePrices'))
const Users = lazy(() => import('./pages/admin/users'))
const AllIssues = lazy(() => import('./pages/allIssues'))
const ReportAnIssue = lazy(() => import('./pages/reportAnIssue'))
const Schools = lazy(() => import('./pages/admin/schools'))
const References = lazy(() => import('./pages/admin/references'))
const PrivateRoute = lazy(() => import('./common/PrivateRoute'))
const PublicRoute = lazy(() => import('./common/PublicRoute'))
const OrderDetails = lazy(() => import('./pages/orderDetails'))
const EvaluationCreation = lazy(() => import('./pages/evaluationCreation'))
const ErrorPage = lazy(() => import('./pages/error'))
const EvaluationDocPreview = lazy(() => import('./pages/document'))
const RevisionEvaluationDocPreview = lazy(() => import('./pages/revisionDocument'))
const PreEvaluationDocPreview = lazy(() => import('./pages/preEvaluationDocument'))
const FinishSignUp = lazy(() => import('./pages/authentication/finishSignUp'))
const EvaluatorsPage = lazy(() => import('./pages/evaluator/edit'))
const PaymentStatusPage = lazy(() => import('./pages/billing/paymentStatus'))
const ContactPage = lazy(() => import('./pages/contact'))
const PreEvaluationDetails = lazy(() => import('./pages/orderDetails/preEvaluation'))
const EvaluatePreEvaluation = lazy(() => import('./components/evaluatePreEvaluation'))
const RevisionOrderDetails = lazy(() => import('./pages/orderDetails/revisionOrderDetails'))
const ReportCopiesOrderDetails = lazy(() => import('./pages/orderDetails/reportCopiesOrderDetails'))
const RevisionEvaluationCreationPage = lazy(() => import('./pages/revisionEvaluationCreationPage'))
const ConfirmUser = lazy(() => import('./pages/authentication/confirmUser'))
const BillingPage = lazy(() => import('./pages/billing/billingPage'))
const VerifyUserEmail = lazy(() => import('./pages/authentication/verifyUserEmail'))
const VerifySuccess = lazy(() => import('./pages/authentication/verifySuccess'))
const CreateAccount = lazy(() => import('./pages/authentication/createAccount'))
const ResetPasswordSecurityQuestion = lazy(() => import('./pages/authentication/resetPasswordSecurityQuestion'))
const ResetPasswordEmail = lazy(() => import('./pages/authentication/resetPasswordEmail'))
const AgentResetPassword = lazy(() => import('./pages/authentication/agentResetPassword'))
const SetNewPassword = lazy(() => import('./pages/authentication/setNewPassword'))
const SetNewPasswordSuccess = lazy(() => import('./pages/authentication/setNewPassowrdSuccess'))
const FormDownload = lazy(() => import('./pages/application/generalForm/FormDownload'))
const FormDownloadNursing = lazy(() => import('./pages/application/generalForm/formDownloadNursing'))
const SetNewPasswordAgent = lazy(() => import('./pages/authentication/setNewPasswordAgent'))
const SetNewPasswordSuccessAgent = lazy(() => import('./pages/authentication/setNewPasswordSuccessAgent'))
const FraudulentList = lazy(() => import('./pages/admin/fraudulentList'))
const NotFoundPage = lazy(() => import('./pages/NotFound/NotFoundPage'))
const paymentSuccessful = lazy(() => import('./pages/preEvaluation/PaymentSuccessful/PaymentSuccessful'))
const DraftApplication = lazy(() => import('./pages/draftApplication/index'))

interface IRoutesProps {}

const Routes = (props: IRoutesProps) => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Switch>
        <PrivateRoute exact path="/" component={Dashboard} />
        <PublicRoute exact path="/eres-sign-in" component={SignIn} />
        <PublicRoute exact path="/agent-sign-in" component={AgentSignIn} />
        <PublicRoute exact path="/sign-in" component={ClientSignIn} />
        <PublicRoute exact path="/sign-up" component={SignUp} />

        <Route exact path="/sign-up-contact" component={FinishSignUp} />

        <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
        <PublicRoute exact path="/forgot-password-agent" component={AgentForgotPassword} />
        <PublicRoute exact path="/reset-password" component={ResetPassword} />
        <PublicRoute exact path="/reset-password-email" component={ResetPasswordEmail} />
        <PublicRoute exact path="/reset-password-agent" component={AgentResetPassword} />
        <PublicRoute exact path="/reset-password-security-question" component={ResetPasswordSecurityQuestion} />
        <PublicRoute exact path="/set-new-password" component={SetNewPassword} />
        <PublicRoute exact path="/set-new-password-agent" component={SetNewPasswordAgent} />
        <PublicRoute exact path="/set-new-password-success" component={SetNewPasswordSuccess} />
        <PublicRoute exact path="/set-new-password-success-agent" component={SetNewPasswordSuccessAgent} />
        <PublicRoute exact path="/verify-user-email" component={VerifyUserEmail} />
        <PublicRoute exact path="/verify-success" component={VerifySuccess} />
        <PublicRoute exact path="/create-account" component={CreateAccount} />
        <PublicRoute exact path="/confirm" component={ConfirmUser} />
        <PublicRoute exact path="/error" component={ErrorPage} />

        <PrivateRoute exact path="/application" component={Application} />
        <PrivateRoute exact path="/application-form-download" component={FormDownload} />
        <PrivateRoute exact path="/payment-successful" component={paymentSuccessful} />
        <PrivateRoute exact path="/form-download" component={FormDownloadNursing} />
        {/* <PrivateRoute exact path="/application/:applicationId" component={DiscountApplication} /> */}

        <PrivateRoute exact path="/additional-services" component={AdditionalServices} />
        <PrivateRoute exact path="/all-users" component={Users} />
        <PrivateRoute exact path="/issues" component={AllIssues} />
        <PrivateRoute exact path="/report-an-issue" component={ReportAnIssue} />
        <PrivateRoute exact path="/schools" component={Schools} />
        <PrivateRoute exact path="/references" component={References} />
        <PrivateRoute exact path="/evaluators" component={EvaluatorsPage} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/notifications" component={NotificationsPage} />

        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/service-prices" component={ServicePrices} />
        <PrivateRoute exact path="/fraudulent-list" component={FraudulentList} />
        <PrivateRoute exact path="/draft-application" component={DraftApplication} />
        <PrivateRoute exact path="/billing/:purpose?" component={BillingPage} />
        <PrivateRoute exact path="/billing/payment/status" component={PaymentStatusPage} />
        {/* application */}
        <PrivateRoute exact path="/dashboard/application/:applicationId" component={OrderDetails} />
        <PrivateRoute
          exact
          path="/dashboard/application/:applicationId/evaluation-creation"
          component={EvaluationCreation}
        />
        <PrivateRoute
          exact
          path="/dashboard/application/:applicationId/evaluation-creation/editor"
          component={EvaluationDocPreview}
        />

        <PrivateRoute
          exact
          path="/dashboard/application/:applicationId/revision-evaluation-creation/editor"
          component={RevisionEvaluationDocPreview}
        />
        {/*Pre Evaluation */}
        <PrivateRoute
          exact
          path="/dashboard/application/:applicationId/pre-evaluation-creation/editor"
          component={PreEvaluationDocPreview}
        />
        {/* revision */}
        <PrivateRoute
          exact
          path="/dashboard/application/:applicationId/revision/:revisionId"
          component={RevisionOrderDetails}
        />
        <PrivateRoute
          exact
          path="/dashboard/application/:applicationId/revision/:revisionId/evaluation-creation"
          component={RevisionEvaluationCreationPage}
        />
        {/* report-copy */}
        <PrivateRoute
          exact
          path="/dashboard/application/:applicationId/report-copy/:reportCopyId"
          component={ReportCopiesOrderDetails}
        />
        {/* pre-evaluation */}
        <PrivateRoute exact path="/dashboard/pre-evaluation/:preEvaluationId" component={PreEvaluationDetails} />
        <PrivateRoute
          exact
          path="/dashboard/pre-evaluation/:preEvaluationId/evaluation-creation"
          component={EvaluatePreEvaluation}
        />

        <PrivateRoute exact path="/contact" component={ContactPage} />
        <PublicRoute component={NotFoundPage} />
      </Switch>
    </Suspense>
  )
}

export default Routes
