import * as React from 'react'
import { BillingTypeContext, IBillingTypeContextItem, IBillingType } from './BillingTypeContext'
import deepcopy from 'deepcopy'
import { IBillableItem } from '../../model/BillableItem'

interface IBillingTypeProviderProps {
  children: React.ReactNode
}

const initialBillingTypeItem: IBillingTypeContextItem = { type: IBillingType.application }

const BillingTypeProvider = (props: IBillingTypeProviderProps) => {
  const [billingType, setBillingType] = React.useState<IBillingTypeContextItem>(deepcopy(initialBillingTypeItem))

  const setBillingTypeItem = (type: IBillingType, id?: number, itemList?: IBillableItem[], additionalItemId?: number) => {
    if (id) {
      if(additionalItemId) {
        setBillingType({ type: type, item_id: id, itemList: itemList, additional_item_id: additionalItemId })
      } else setBillingType({ type: type, item_id: id, itemList: itemList })
    } else setBillingType({ type: IBillingType.full }) //the only type without id
  }

  return (
    <BillingTypeContext.Provider value={{ billingType, setBillingTypeItem }}>
      {props.children}
    </BillingTypeContext.Provider>
  )
}

export default BillingTypeProvider
