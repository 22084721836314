/* eslint-disable array-callback-return */
import {
  IApplicationBase,
  IApplication,
  IRequiredDocuments,
  IApplicationId,
  IBillingService,
  pdfProps,
} from '../model/Application'
import { IApplicationStatus } from '../model/ApplicationStatus'
import { IApplicationApprovals } from '../model/ApplicationApprovals'
import { requestPaginated, apiClient } from '@jetkit/react'
import {
  ICommissionFilter,
  IEvaluatorAdditionalServiceFilter,
  IEvaluatorApplicationFilter,
  INewEvaluator,
  IUserType,
} from '../model/User'
import { DeepPartial } from 'redux'
import { IAssignmentFull, IAssignmentEditRequest, IUnpaidComissions } from '../model/Assignment'
import { IComplicationItem, IComplication } from '../model/Complication'
import { IBillableItem } from '../model/BillableItem'
import { PurposeType, purposeListCodes } from '../model/Purpose'
import { IRequiredDocument } from '../pages/orderDetails/requiredDocuments'
import { openDownloadUrl } from './common'
import { PaymentMethodName } from '../model/PaymentMethod'
import { IAdditionalServiceItem } from '../model/AdditionalServices'
import { IRevision } from '../model/Revision'

function getUrl(pdfData: pdfProps) { 
  const nullChecker = (value: any,key?:any) => {
    if (typeof value === 'string' && value.length > 0) {
      if(key === "R") {
        return value[0].toUpperCase() + value.substring(1) + '-'
      } else if(key === "F") {
        return value[0].toUpperCase() + value.substring(1) + '-'
      } else if(key === "L") {
        return value[0].toUpperCase() + value.substring(1)
      } else if(key === "C") {
        const cntArray = JSON.parse(value)
        let contArr = []
        if(Array.isArray(cntArray)) {
          let i: number
          for(i=0; i < cntArray.length; i++){
             contArr.push(cntArray[i])
          }
        }
        let countryName = [...new Set(contArr)];
        return countryName + '--'
      } else {
        return value + '--'
      }
    } else {
      return ''
    }
  }

  const url = nullChecker(pdfData.dueDate) + nullChecker(pdfData.ref) + nullChecker(pdfData.country,'C') + nullChecker(purposeListCodes[pdfData.type]) + nullChecker(pdfData.state) + nullChecker(pdfData.rushService,'R') + nullChecker(pdfData.firstName,'F') + nullChecker(pdfData.lastName,'L'); 
  
  return url
}

export const getApplications = requestPaginated<IApplicationBase[]>({ url: '/application' })
export const getApplicationsForEvaluator = requestPaginated<IApplicationBase[]>({ url: '/application/for-evaluator' })

export const getDataForExcel = async (filters): Promise<void> => {
  await apiClient
    .get(`/application/excel-data${filters}`, {
      headers: {
        'Content-Type': 'application/vnd.ms-excel',
      },
      responseType: 'blob',
    })
    .then((response) => {
      const fileName = response.headers['content-disposition'].split('filename=')[1].split('.')[0]
      openDownloadUrl(response.data, fileName, 'xlsx')
    })
}
export const getApplication = async (id: number | string, userRole?: IUserType): Promise<IApplication> => {
  const endpoint = `/application/${id}` + (userRole === 'admin' ? '/full-info' : '')
  return (await apiClient.get(endpoint)).data
}

export const updateApplicationbyId = async (application_id: number, data: object): Promise<IApplication> => {
  const endpoint = `/application/${application_id}`
  return (await apiClient.patch(endpoint, data)).data
}
export const updateAdditionalServiceNoteBoardbyId = async (
  application_id: number,
  data: object
): Promise<IApplication> => {
  const endpoint = `/additional-service/${application_id}/comment-update`
  return (await apiClient.patch(endpoint, data)).data
}
export const updateApplicationbyIdAdditionalBoard = async (
  application_id: number,
  data: object
): Promise<IApplication> => {
  const endpoint = `/application/board-update/${application_id}`
  return (await apiClient.patch(endpoint, data)).data
}

export const updateApplicationLifebyId = async (application_id: number): Promise<IApplication> => {
  const endpoint = `/application/${application_id}/update-life`
  return (await apiClient.post(endpoint)).data
}

export const assignAgent = async (application_id: number, data: object): Promise<IApplication> => {
  const endpoint = `/application/${application_id}/assign-agent`
  return (await apiClient.post(endpoint, data)).data
}

export const approveReport = async (id: number): Promise<void> =>
  (await apiClient.post(`/application/${id}/approve-report`)).data

export const rejectReasonSendToEvaluator = async (id: number, rejectionReason): Promise<void> =>
  await apiClient.post(`/application/${id}/reject-email`, { reason: rejectionReason })

export const rejectUnofficialReport = async (id: number): Promise<void> =>
  (await apiClient.post(`/application/${id}/reject-reports`)).data

export const updateApplication = async (application: DeepPartial<IApplication>, id: number): Promise<IApplication> =>
  (await apiClient.patch(`/application/${id}`, application)).data

export const updateApplicationDiscount = async (discount: string, id: number): Promise<IApplication> =>
  (await apiClient.post(`/application/${id}/discount`, { discount })).data

export const updateApplicationStatus = async (status: IApplicationStatus, id: number): Promise<IApplication> =>
  (await apiClient.patch(`/application/${id}/update-status`, status)).data

export const assignEvaluators = async (newEvaluators: INewEvaluator[], targetId: number): Promise<void> =>
  (await apiClient.post(`/application/${targetId}/assign-evaluators`, newEvaluators)).data

// assignment represents an application assigned to an evaluator (with payment status, commission, etc)
export const getAssignmentsForEvaluator = (evaluatorId: string) =>
  requestPaginated<IAssignmentFull[]>({ url: `/assignment/for-evaluator/${evaluatorId}` })()

export const getAssignmentsForEvaluatorById = async (evaluatorId: string): Promise<IAssignmentFull[]> =>
  (await apiClient.get(`/assignment/for-evaluator/${evaluatorId}`)).data

const getUpdatedUrl = (defualtUrl?: any, filter?: any) => {
  let url = defualtUrl
  if (filter) {
    Object.keys(filter)?.map((val, index) => {
      if (index === 0) {
        url += `?${val}=${filter?.[val]}`
      } else {
        url += `&&${val}=${filter?.[val]}`
      }
    })
  }
  return url
}

export const getUnpaidCommissionById = async (
  evaluatorId: string,
  filter?: ICommissionFilter
): Promise<IUnpaidComissions> => {
  let url = getUpdatedUrl(`/assignment/${evaluatorId}/unpaid-comissions`, filter)
  return (await apiClient.get(url)).data
}

export const getAssignmentsForEvaluatorFilterAPI = (evaluatorId: string, filter?: IEvaluatorApplicationFilter) => {
  let url = getUpdatedUrl(`/assignment/for-evaluator/${evaluatorId}`, filter)
  return requestPaginated<IAssignmentFull[]>({ url })()
}

export const getAdditionalAssignmentsForEvaluatorFilterAPI = (
  evaluatorId: string,
  filter?: IEvaluatorAdditionalServiceFilter
) => {
  let url = getUpdatedUrl(`/additional-service/for-evaluator/${evaluatorId}`, filter)
  return requestPaginated<IAdditionalServiceItem[]>({ url })()
}

export const updateApplicationAssignment = async (
  assignment: Partial<IAssignmentEditRequest>,
  id: number
): Promise<IAssignmentFull> => (await apiClient.patch(`/assignment/${id}`, assignment)).data

export const updateAdditionalAssignment = async (
  assignment: Partial<IAdditionalServiceItem>,
  id: number
): Promise<IAdditionalServiceItem> => (await apiClient.patch(`/additional-service/${id}`, assignment)).data

export const addApplication = async (application?: IApplication): Promise<IApplication> => {
  const response = await apiClient.post('/application', application)
  return response.data
}

export const editApplication = async (application: IApplication): Promise<IApplication> => {
  const response = await apiClient.patch(`/application/${application.id}`, application)
  return response.data
}

export const deleteApplication = async (applicationId: number): Promise<void> => {
  await apiClient.delete(`/application/${applicationId}`)
}

export const draftApplication = async (applicationId: number): Promise<void> => {
  await apiClient.post(`/application/draft/${applicationId}`)
}

export const getDraftApplication = requestPaginated<any>({
  url: '/application/drafted-applications',
})

export const deleteDraftApplication = async (draftApplicationId: number): Promise<void> => {
  await apiClient.delete(`/application/draft/${draftApplicationId}`)
}

export const acceptEvaluation = async (applicationId: number): Promise<void> =>
  await apiClient.post(`/application/${applicationId}/accept`)

export const updateApplicationApprovals = async (
  approvals: Partial<IApplicationApprovals>,
  id: number
): Promise<IApplication> => (await apiClient.post(`/application/${id}/update-approvals`, approvals)).data

export const addComplication = async (complication: IComplicationItem, applicationId: number): Promise<void> =>
  (await apiClient.post(`/application/${applicationId}/complication`, complication)).data

export const addSignature = async (applicationId: number, signature: string): Promise<void> =>
  (await apiClient.post(`application/${applicationId}/add-signature`, { signature_field: signature })).data

export const updateComplicationDescription = async (
  complication: IComplicationItem,
  applicationId: number
): Promise<void> => (await apiClient.patch(`/application/${applicationId}/complication`, complication)).data

export const deleteComplication = async (type: IComplication, applicationId: number): Promise<void> =>
  (await apiClient.delete(`/application/${applicationId}/complication`, { params: { type } })).data //todo: change to proper deleting (API missing)

export const accessApplicationFinalReport = async (applicationId: number | string): Promise<{ url: string }> =>
  (await apiClient.get(`/application/${applicationId}/access-final-report`)).data

export const accessUnofficialReport = async (reportId: number | string): Promise<{ download_url: string }> =>
  (await apiClient.get(`application/report/${reportId}/download`)).data

export const getApplicationRequiredDocuments = async (id: number | string): Promise<IRequiredDocuments> =>
  (await apiClient.get(`/application/${id}/required-documents`)).data

export const finishEvaluation = async (applicationId: number): Promise<void> =>
  (await apiClient.post(`/application/${applicationId}/finish`)).data

export const closeEvaluation = async (applicationId: number): Promise<void> =>
  (await apiClient.post(`/application/close-case/${applicationId}`)).data

export const downloadOtherNameAttachment = async (applicationId: number): Promise<void> => {
  const res = await apiClient.get(`/application/${applicationId}/other-name-scan-copy/download`)
  const downloadUrl = res.data.download_url

  // download downloadUrl
  window.open(downloadUrl)
}

export const downloadAuthorizationForm = async (applicationId: number): Promise<void> => {
  const res = await apiClient.get(`/application/authorization-form/${applicationId}/download`)
  const downloadUrl = res.data.download_url

  // download downloadUrl
  window.open(downloadUrl)
}
export const deleteAuthorizationFormAttachment = async (id: number): Promise<void> =>
  await apiClient.delete(`/application/authorization-form/${id}`)

export const addCustomCharge = async (billableItem: Partial<IBillableItem>): Promise<IBillableItem> =>
  (await apiClient.post(`/bill/custom`, billableItem)).data

export const updateCustomCharge = async (billableItem: Partial<IBillableItem>): Promise<IBillableItem> =>
  (await apiClient.patch(`/bill/custom/${billableItem.id}`, billableItem)).data

export const deleteCustomCharge = async (billId: number): Promise<void> =>
  (await apiClient.delete(`/bill/custom/${billId}`)).data

  export const deleteReaCustomCharge = async (billId: number): Promise<void> =>
  (await apiClient.delete(`/bill/reactivation/${billId}`)).data

export const submitApplication = async (applicationId: number): Promise<void> =>
  (await apiClient.post(`/application/${applicationId}/submit`)).data

export const addDiscountApplication = async (applicationId: number, purpose: PurposeType): Promise<IApplicationId> =>
  (await apiClient.post(`/application/${applicationId}/revision/application-copy`, { purpose: purpose })).data

export const getApplicationDocuments = async (id: number | string): Promise<IRequiredDocument[]> =>
  (await apiClient.get(`/application/${id}/documents`)).data

export const getApplicationDocumentList = async (id: number | string): Promise<{ documents: string[] }> =>
  (await apiClient.get(`/application/${id}/required-documents`)).data

/*export const addApplicationDocumentList = async (name: string, document_type: string): Promise<{ documents: string[] }> =>
  (await apiClient.get(`/application/${id}/required-documents`)).data*/

export const approveApplicationDocument = async (
  applicationId: number,
  documentId: number,
  isApproved: boolean
): Promise<void> =>
  (
    await apiClient.post(`/application/${applicationId}/documents/${documentId}/approve-document`, {
      is_approved: isApproved,
    })
  ).data

export const getApplicationBillingServices = async (
  purpose: PurposeType,
  isAdditionalCopy: boolean = false
): Promise<IBillingService[]> =>
  (await apiClient.post(`/application/billing-services/${purpose}`, { is_additional_copy: isAdditionalCopy })).data

export const getApplicationFormPdf = async (applicationId: number, pdfData: pdfProps): Promise<void> => {
  const url = getUrl(pdfData)
  const res = await apiClient.get(`/application/${applicationId}/application-form`, {
    responseType: 'arraybuffer',
  })
  openDownloadUrl(res.data, url)
}

export const updatePaymentType = async (applicationId: number, payment_type: PaymentMethodName): Promise<void> => {
  return await apiClient.patch(`/application/${applicationId}/payment-type`, { payment_type })
}

function getRevUrl(application: IApplication, revisionItem: IRevision, countryVal: string) {
  const ref = revisionItem.revision_ref
  const firstName = application.applicant.first_name
  const lastName = application.applicant.last_name
  const dueDate = application.deadline_date
  const country = countryVal
  const type = purposeListCodes[application.purpose]

  const rushService = application.rush_service
  const nullChecker = (value: any,key?:any) => {
    if (typeof value === 'string' && value.length > 0) {
      if(key === "R") {
        return value[0].toUpperCase() + value.substring(1) + '-'
      } else if(key === "F") {
        return value[0].toUpperCase() + value.substring(1) + '-'
      } else if(key === "L") {
        return value[0].toUpperCase() + value.substring(1)
      } else if(key === "C") {
        const cntArray = JSON.parse(value)
        let contArr = []
        if(Array.isArray(cntArray)) {
          let i: number
          for(i=0; i < cntArray.length; i++){
             contArr.push(cntArray[i])
          }
        }
        let countryName = [...new Set(contArr)];
        return countryName + '--'
      } else {
        return value + '--'
      }
    } else {
      return ''
    }
  }

  const url = nullChecker(dueDate) + nullChecker(ref)+ nullChecker(country,'C') + nullChecker(type) + nullChecker(rushService,'R') + nullChecker(firstName,'F') + nullChecker(lastName,'L'); return url
}

export const getRevisionFormPdf = async (application: IApplication, revisionItem: IRevision, countryVal: string, dataPayload: string): Promise<void> => {

  const url = getRevUrl(application,revisionItem,countryVal)

  const res = await apiClient.post(`/application/${application.id}/additional-application-form/${revisionItem.id}`,{data: dataPayload},{responseType: 'arraybuffer'});

  openDownloadUrl(res.data, url)
}
