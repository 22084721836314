import * as React from 'react'
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core/styles'
import EresLogo from '../img/eres_logo.png'

const styles = (theme: Theme) =>
  createStyles({
    logoContainer: {
      width: theme.logo.width,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: '100%',
    },
  })

interface ILogoProps extends WithStyles<typeof styles> {}

const Logo: React.FC<ILogoProps> = ({ classes }) => {
  return (
    <div className={classes.logoContainer}>
      <img alt="logo" src={EresLogo} className={classes.image} />
    </div>
  )
}

export default withStyles(styles)(Logo)
