import { ICountry } from '../model/Country'
import { apiClient, requestPaginated } from '@jetkit/react'
import { IOptions, getOption } from '../model/Select'
import { IReferenceCollection, IReference } from '../model/Reference'

export const getCountries = async (): Promise<ICountry[]> => (await apiClient.get<ICountry[]>('/country')).data

export const getReferences = requestPaginated<IReferenceCollection[]>({ url: '/country/reference' })

export const updateReferences = async (countryId: string | number, references: Partial<IReference>[]) =>
  (await apiClient.put(`/country/${countryId}/reference`, references)).data

export const deleteReferences = async (references: number[]) =>
  await apiClient.delete(`/country/reference`, { data: references })

export const getReferencesByCountry = async (countryId: string | number) =>
  (await apiClient.get<IReference[]>(`/country/${countryId}/reference`)).data

export const getCountriesList = async (): Promise<IOptions[]> => {
  const res = await apiClient.get<ICountry[]>(`/country`)
  const options: IOptions[] = res.data.map(elem => {
    const countryId: string = elem.id.toString()
    return {
      value: countryId,
      label: elem.name,
    }
  })
  return options
}

export const getCountry = async (id: number): Promise<ICountry> =>
  (await apiClient.get<ICountry>(`/country/${id}`)).data

export const getCountryOption = async (id: number): Promise<IOptions> => {
  const res = await apiClient.get<ICountry>(`/country/${id}`)
  return getOption(res.data.id, res.data.name)
}
