import * as React from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { StoreProvider } from 'easy-peasy'
import App from './App'
import store, { persistor } from './store'
import * as Sentry from '@sentry/browser'

Sentry.init({ dsn: `${process.env.REACT_APP_SENTRY_DSN}` })

const AppWithStore: React.FC = () => {
  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </StoreProvider>
  )
}

export default AppWithStore
