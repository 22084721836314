import moment from 'moment'

export const AGREEMENT_DATE_FORMAT = 'D MMMM YYYY'

export const DATE_API_FORMAT = 'YYYY-MM-DD'
export const MONTH_DATE_YEAR_FORMAT = 'MMM DD, YYYY'
export const MONTH_DATE_YEAR_TIME_FORMAT = 'MM YYYY h:mm:ss a'
export const DATE_MONTH_YEAR_FORMAT = 'DD MMM YYYY'
export const formatDateToHumanFriendly = (date: Date | string): string => {
  if (!date) return null
  if (!(date instanceof Date)) {
    date = new Date(date)
  }
  return moment(date).format(MONTH_DATE_YEAR_FORMAT)
}

export const formatDateToHumanFriendlyWithHour = (date: Date | string): string => {
  if (!(date instanceof Date)) {
    date = new Date(date)
  }
  return moment(date).format(MONTH_DATE_YEAR_TIME_FORMAT)
}

export const formatRefNumber = (refNumber: string): string =>
  '#' + refNumber?.slice(0, 2) + '-' + refNumber?.slice(2, 4) + '-' + refNumber?.slice(4)

export const capitalize = (text: string) =>
  text
    ?.toLowerCase()
    ?.split(' ')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ')

export const displayName = (name: string): string => capitalize(name?.split('_')?.join(' '))

export const formatUsername = (firstName?: string, middleName?: string, lastName?: string) => {
  let name = ''
  if (firstName) {
    name = firstName + ' '
  }
  if (middleName) {
    name += middleName + ' '
  }
  if (lastName) {
    name += lastName
  }
  return name
}
export const ellipsize = (text: string, ellipsizeAt: number) =>
  ellipsizeAt < text?.length ? text?.slice(0, ellipsizeAt - 1) + '…' : text

export const extractPhone = (value: string): string | undefined => {
  const match = value.match(/(\d+)/)
  if (match) return match.join('')
  return undefined
}

export const initials = (firstName?: string, lastName?: string) => {
  return (
    firstName &&
    firstName?.toUpperCase().slice(0, 1) +
      (firstName && lastName && '.') +
      (lastName && lastName?.toUpperCase()?.slice(0, 1))
  )
}

export const formatPrice = (priceInCents: number) => (priceInCents / 100).toFixed(2)

export const isAcceptableNameChar = (event: React.KeyboardEvent<HTMLDivElement>): boolean | void => {
  const reg = /[~`!@#$%^&*()+=_[\]';,/{}|":<>?(\d+)]/ //don't allow any special characters
  return reg.test(event.key) && event.preventDefault()
}

export const upperFirst = (str: string) => {
  return str?.[0]?.toUpperCase() + str?.slice(1)
}

export const getAddressInfo = (personalInfoData) => {
  const addressData = {
    line_1: personalInfoData?.line_1,
    line_2: personalInfoData?.line_2,
    city: personalInfoData?.city,
    country_id: personalInfoData?.country_id,
    postal_code: personalInfoData?.postal_code,
    state: personalInfoData?.state,
  }
  return addressData
}
export const getUserInfo = (personalInfoData) => {
  const mainInfo = {
    first_name: personalInfoData?.first_name,
    middle_name: personalInfoData.middle_name,
    last_name: personalInfoData?.last_name,
    dob: personalInfoData?.dob ? personalInfoData?.dob : null,
    email: personalInfoData.email,
    native_language_id: personalInfoData?.native_language_id,
    gender: String(personalInfoData?.gender).toLowerCase(),
    phone_number: personalInfoData?.phone_number,
  }
  if (personalInfoData?.other_first_name) mainInfo['other_first_name'] = personalInfoData?.other_first_name
  if (personalInfoData?.other_middle_name) mainInfo['other_middle_name'] = personalInfoData?.other_middle_name
  if (personalInfoData?.other_last_name) mainInfo['other_last_name'] = personalInfoData?.other_last_name
  return mainInfo
}

export const getUppercaseLetter = (val) => {
  return String(val)?.toUpperCase()
}

export const getDocStatusName = (val) => {
  const value = String(val)
    .split('_')
    ?.map((item) => item?.[0]?.toUpperCase() + item?.slice(1))
    ?.join(' ')
  return value
}
