import * as React from 'react'

interface IApplicationProgressContext {
  applicationInProgress: boolean
  setApplicationInProgress: () => void
}

const setApplicationInProgress = () => {}

export const ApplicationProgressContext = React.createContext<IApplicationProgressContext>({
  applicationInProgress: false,
  setApplicationInProgress,
})
