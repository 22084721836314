import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { deleteApplication, draftApplication } from '../../../services/applicationService'
import { handleErrorWithSentry } from '../../../services/common'
import useRouter from 'use-react-router'
import CloseIcon from '@material-ui/icons/Close'
import { notify } from '@jetkit/react'
import { useStoreState } from '../../../store'

export default function SaveDataDialog({ open, setOpen }) {
  const { history } = useRouter()
  const appId = useStoreState((state) => state.applicationIdStore.applicationStoreId)

  const handleClose = () => {
    setOpen(false)
  }
  const handleDelete = () => {
    try {
      if (appId) {
        deleteApplication(Number(appId))
      }
    } catch (error) {
      handleErrorWithSentry(error)
    } finally {
      setOpen(false)
      history.push('/dashboard')
    }
  }
  const handleSave = () => {
    try {
      if (appId) {
        draftApplication(Number(appId)).then(() => {
          notify.success('Application Drafted Successfully')
        })
      }
    } catch (error) {
      handleErrorWithSentry(error)
    } finally {
      setOpen(false)
      history.push('/dashboard')
    }
  }

  return (
    <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center">
          <Typography>Save this incomplete form as a draft?</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Click on Yes to save the Application form as a draft.
          <br />
          If you click on No, your Data will be lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete}>No</Button>
        <Button onClick={handleSave}>Yes</Button>
      </DialogActions>
    </Dialog>
  )
}
