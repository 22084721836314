import { apiClient } from '@jetkit/react'
import { INotification } from '../model/Notification'

export const getNotifications = async (): Promise<INotification[]> => (await apiClient.get(`/notification/unread?page_size=100`)).data

export const removePerticulerNotification = async (id: string): Promise<INotification> =>
  (await apiClient.post(`/notification/${id}/mark-as-read`)).data
export const removeAllNotification = async (): Promise<INotification> =>
  (await apiClient.post(`/notification/mark-all-read`)).data

export const getPreEvaluationEmailNotification = async (preEvaluationId: number): Promise<void> =>
  (await apiClient.get(`/additional-service/card-payment-received/${preEvaluationId}`)).data
