import { apiClient } from '@jetkit/react'
import { clearAuthTokens } from 'axios-jwt'
import { useStoreActions, useStoreState } from '../store'
import { useHistory } from 'react-router-dom'

export const useApiErrorInterceptor = () => {
  const history = useHistory()
  const currentUser = useStoreState((state) => state.user.current)
  const removeCurrentUser = useStoreActions((actions) => actions.user.removeCurrent)

  const setupInterceptor = () => {
    apiClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          if (currentUser?.role === 'client') {
            history.push('/sign-in')
          } else if (currentUser?.role === 'agent') {
            history.push('/agent-sign-in')
          } else {
            history.push('/eres-sign-in')
          }
          removeCurrentUser()
          clearAuthTokens()
        }
        return Promise.reject(error)
      }
    )
  }

  return setupInterceptor
}
