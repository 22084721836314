import * as localForage from 'localforage'
import { persistReducer, persistStore } from 'redux-persist'
import { createStore, createTypedHooks } from 'easy-peasy'
import { IUserState, userState } from './user'
import { INotificationsState, notificationsState } from './notifications'
import {
  additionalEvaluationFilterState,
  additionalEvaluationSearchState,
  additionalPayementFilterState,
  additionalServiceSearchState,
  agentSelectFilterState,
  applicaitonEvaluationSearchState,
  applicationEvaluationFilterState,
  applicationIdStoreState,
  applicationPayementFilterState,
  applicationSearchState,
  columnFilterState,
  dashboardMonthFilterState,
  dashboardSortState,
  draftApplicationFilterState,
  draftApplicationSearchState,
  draftApplicationSortState,
  fraudulentManagementSortState,
  fraudulentSearchState,
  IApplicationIdStoreState,
  IColumnFilterState,
  IColumnSortState,
  IEvaluatorFilterState,
  ISearchFilterState,
  IUnassignedFilterState,
  preEvaluationSortState,
  reportIssueSearchState,
  schoolSearchState,
  schoolSortState,
  selectedCountryFilterState,
  selectedEvaluatorFilterState,
  selectedPaymentStatusFilterState,
  selectedApplicationStatusFilterState,
  selectedApplicationProgressStatusFilterState,
  unassignedFilterState,
  userManagementSearchState,
  userManagementSortState,
  IPaymentStatusFilterState,
  IApplicationStatusFilterState,
  IApplicationProgressStatusFilterState,
  applicationResponsiveStoreState,
  IApplicationResponsiveStoreState,
  applicationRefSearchState,
} from './searchFilter'

// global state
export interface StoreModel {
  user: IUserState
  notifications: INotificationsState
  application: ISearchFilterState
  applicationRef: ISearchFilterState
  dashboardMonthFilter: ISearchFilterState
  dashboardUnassignedFilter: IUnassignedFilterState
  dashboardColumnFilter: IColumnFilterState
  additionalService: ISearchFilterState
  userManagement: ISearchFilterState
  school: ISearchFilterState
  schoolCountryFilter: ISearchFilterState
  reportedIssueFilter: ISearchFilterState
  dashboardSort: IColumnSortState
  preEvaluationSort: IColumnSortState
  userManagementSort: IColumnSortState
  schoolSort: IColumnSortState
  fraudulentManagementSort: IColumnSortState
  fraudulentManagement: ISearchFilterState
  selectedEvaluator: IEvaluatorFilterState
  draftApplicationSearch: ISearchFilterState
  draftApplicationPurposeFilter: ISearchFilterState
  draftApplicationSort: IColumnSortState
  applicationPayementFilterState: ISearchFilterState
  applicationEvaluationFilterState: ISearchFilterState
  additionalPayementFilterState: ISearchFilterState
  additionalEvaluationFilterState: ISearchFilterState
  applicaitonEvaluationSearchState: ISearchFilterState
  additionalEvaluationSearchState: ISearchFilterState
  agentFilterState: ISearchFilterState
  applicationIdStore: IApplicationIdStoreState
  selectedPaymentStatus?: IPaymentStatusFilterState
  selectedApplicationStatus?: IApplicationStatusFilterState
  selectedApplicationProgressStatus?:IApplicationProgressStatusFilterState
  applicationResponsiveState?:IApplicationResponsiveStoreState
}

// instance representing initial state
export const model: StoreModel = {
  user: userState,
  applicationIdStore: applicationIdStoreState,
  applicationResponsiveState:applicationResponsiveStoreState,
  notifications: notificationsState,
  application: applicationSearchState,
  applicationRef: applicationRefSearchState,
  dashboardMonthFilter: dashboardMonthFilterState,
  dashboardUnassignedFilter: unassignedFilterState,
  dashboardColumnFilter: columnFilterState,
  selectedEvaluator: selectedEvaluatorFilterState,
  selectedPaymentStatus: selectedPaymentStatusFilterState,
  selectedApplicationStatus: selectedApplicationStatusFilterState,
  selectedApplicationProgressStatus:selectedApplicationProgressStatusFilterState,
  schoolCountryFilter: selectedCountryFilterState,
  additionalService: additionalServiceSearchState,
  userManagement: userManagementSearchState,
  dashboardSort: dashboardSortState,
  preEvaluationSort: preEvaluationSortState,
  userManagementSort: userManagementSortState,
  schoolSort: schoolSortState,
  fraudulentManagementSort: fraudulentManagementSortState,
  school: schoolSearchState,
  fraudulentManagement: fraudulentSearchState,
  draftApplicationSearch: draftApplicationSearchState,
  draftApplicationPurposeFilter: draftApplicationFilterState,
  draftApplicationSort: draftApplicationSortState,
  applicationPayementFilterState: applicationPayementFilterState,
  applicationEvaluationFilterState: applicationEvaluationFilterState,
  additionalPayementFilterState: additionalPayementFilterState,
  additionalEvaluationFilterState: additionalEvaluationFilterState,
  applicaitonEvaluationSearchState: applicaitonEvaluationSearchState,
  additionalEvaluationSearchState: additionalEvaluationSearchState,
  agentFilterState: agentSelectFilterState,
  reportedIssueFilter: reportIssueSearchState,
}

// persist our config to localforage
const persistConfig = {
  key: 'root',
  storage: localForage,
}
const store = createStore(model, {
  reducerEnhancer: (reducer) => persistReducer(persistConfig, reducer),
})
export const persistor = persistStore(store) // needed for PersistGate

export default store

// easy access to typed hooks for state and actions
// https://easy-peasy-v3.now.sh/docs/typescript-tutorial/typed-hooks.html
const typedHooks = createTypedHooks<StoreModel>()
export const useStoreActions = typedHooks.useStoreActions
export const useStoreDispatch = typedHooks.useStoreDispatch
export const useStoreState = typedHooks.useStoreState
