import { IUser, IEvaluator, IClient, IUserType, TEvaluatorLevel, IAgent } from '../model/User'
import { requestPaginated, apiClient, isLoggedIn } from '@jetkit/react'
import { OptionsType } from 'react-select'
import { formatUsername } from '../utils/formatters'
import Axios from 'axios'
import FileDownload from 'js-file-download'

export const getUsers = requestPaginated<IUser[]>({ url: '/user' })

// Evaluators
export const getEvaluators = async (): Promise<IEvaluator[]> => (await apiClient.get('/evaluator')).data
export const getAgents = async (): Promise<IAgent[]> => {
  return (await apiClient.get<IAgent[]>('/agent/list')).data
}
export const getEvaluator = async (evaluatorId: string): Promise<IEvaluator> =>
  (await apiClient.get(`/evaluator/${evaluatorId}`)).data
export const editEvaluator = async (evaluator: Partial<IEvaluator>): Promise<IEvaluator> =>
  (await apiClient.patch(`/evaluator/${evaluator.id}`, evaluator)).data

export const editEvaluatorLevel = async (evaluatorId: string, evaluatorLevel: TEvaluatorLevel): Promise<void> =>
  (await apiClient.post(`/evaluator/${evaluatorId}/change-role`, { evaluator_level: evaluatorLevel })).data

export const getEvaluatorsList = async (): Promise<OptionsType> => {
  const res = await apiClient.get<IEvaluator[]>(`/evaluator`)
  const options: OptionsType = res.data.map((elem) => {
    const evaluatorId: string = elem.id.toString()
    return {
      value: evaluatorId,
      label: formatUsername(elem.first_name, elem.middle_name, elem.last_name),
    }
  })
  return options
}

export const addUser = async (user: Partial<IUser>): Promise<IUser> => {
  const response = await apiClient.post('/user', user)
  return response.data
}

export const deleteUser = async (userId: string): Promise<void> => {
  await apiClient.delete(`/user/${userId}`)
}
export const getClient = async (clientId: string): Promise<IClient> => (await apiClient.get(`/client/${clientId}`)).data

/**
 * Edit current logged in user
 */
export const editMe = async (fields: Partial<IUser>): Promise<IUser> => (await apiClient.patch(`/user/me`, fields)).data

export const getMe = async <UT = IUser>(): Promise<UT> => (await apiClient.get(`/user/me`)).data

/**
 * Admin edit user
 */
export const editUser = async (user: Partial<IUser>): Promise<IUser> =>
  (await apiClient.patch(`/user/${user.id}`, user)).data
  export const editTermsAndCondition= async (id: any,data: any): Promise<IUser> =>
  (await apiClient.patch(`/user/${id}/update-tc`, data)).data

export const editRole = async (
  userId: string,
  role: IUserType,
  evaluatorLevel: TEvaluatorLevel = 'senior'
): Promise<void> =>
  (await apiClient.post(`/user/${userId}/change-role`, { role: role, evaluator_level: evaluatorLevel })).data

export const reloadMe = async (): Promise<IUser | undefined> => {
  if (!isLoggedIn()) return

  const res = await getMe()
  return res
}

export interface IS3PresignedPutUrl {
  url: string
  headers: object
}

export interface IS3PresignedGetUrl {
  url: string
}

export const getSignatureUploadUrl = async (pk: string, mimeType: string): Promise<IS3PresignedPutUrl> =>
  (await apiClient.post(`application/${pk}/get_upload_signature_url`, { mimeType: mimeType })).data

export const uploadSignature = async (presigned_url: IS3PresignedPutUrl, file: File): Promise<void> => {
  await Axios.put(presigned_url.url, file, {
    headers: {
      ...presigned_url.headers,
    },
  })
}

export const getViewSignatureUrl = async (pk: string): Promise<IS3PresignedGetUrl> =>
  (await apiClient.get(`application/get_view_signature_url/${pk}`)).data

export const getSignature = async (presigned_url: string, filename: string): Promise<void> => {
  const result = await Axios.get(presigned_url)
  FileDownload(result.data, filename)
}
