import { apiClient } from '@jetkit/react'
import { IOptions } from '../model/Select'
import { ILanguage } from '../model/Language'

export const getLanguage = async (languageId: number): Promise<ILanguage> =>
  (await apiClient.get<ILanguage>(`/language/${languageId}`)).data

export const getLanguages = async (): Promise<ILanguage[]> => (await apiClient.get<ILanguage[]>('/language')).data

//we export this as IOptions list for out select
export const getLanguagesList = async (): Promise<IOptions[]> => {
  const res = await apiClient.get<ILanguage[]>(`/language`)
  const options: IOptions[] = res.data.map(elem => {
    const countryId: string = elem.id.toString()
    return {
      value: countryId,
      label: elem.name,
    }
  })
  return options
}
