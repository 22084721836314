import * as React from 'react'
import { IBillableItem } from '../../model/BillableItem'

export enum IBillingType {
  application = 'application',
  bill = 'bill',
  full = 'full',
  additionalService = 'additionalService',
}

export interface IBillingTypeContextItem {
  type: IBillingType
  item_id?: number
  additional_item_id?: number
  itemList?: IBillableItem[]
}

interface IBillingTypeContext {
  billingType: IBillingTypeContextItem
  setBillingTypeItem: (type: IBillingType, id?: number, itemList?: IBillableItem[], additional_item_id?: number) => void
}

export const BillingTypeContext = React.createContext<IBillingTypeContext>({
  billingType: { type: IBillingType.full, item_id: 0, itemList: [], additional_item_id: 0 },
  setBillingTypeItem: () => {},
})
