import { Gender } from '../model/Gender'
import { IOptions, emptyOptions } from '../model/Select'
import deepcopy from 'deepcopy'

export const getBoolean = (value: string): boolean => {
  return value.toLowerCase() === 'yes'
}

export const getGender = (value: string): Gender => {
  switch (value) {
    case 'female':
      return 'female'
    case 'male':
      return 'male'
    case 'other':
      return 'other'
    default:
      return 'other'
  }
}

export const getBooleanOptions = (value: boolean): IOptions => {
  if (value) {
    return { label: 'Yes', value: 'yes' }
  } else return { label: 'No', value: 'no' }
}

export const getBooleanValue = (value: string): boolean => {
  if (value === 'true') {
    return true
  } else return false
}

export const getRandomValue = () => Math.floor(Math.random() * Math.floor(1000000))

export const getOptionByValue = (optionList: IOptions[], value?: string | number): IOptions => {
  const itemByValue = optionList.find((item) => item.value === value)
  if (itemByValue) return itemByValue
  return deepcopy(emptyOptions)
}

export const userRole = ['client', 'evaluator', 'front_desk', 'agent']

export const evaluationPermissions = ['admin', 'evaluator', 'front_desk']

export const evaluationEditPermissions = ['admin', 'front_desk']

export const purpose = ['acupuncture', 'nursing', 'cpa']

export const allUserRole = ['admin', 'front_desk', 'agent', 'client', 'evaluator']

export const baseEresUrl = 'https://www.eres.com'

export const basicServiceCodes = ['U', 'NB', 'AB', 'HG', 'HP', 'NURSING_CHANGE_BOARD', 'V', 'REA']

export const maillingServiceCodes = ['I', 'M1', 'M2', 'DD']

export const dueDateCodes = ['ACP', 'NUR', 'GE']

export const hasLicenseInformation = ['nursing', 'acupuncture', 'teaching_credential']
export const documentToReach = ['mail', 'email', 'pickup', 'mail_default']

export const requiredDocsEnums = [
  { value: 'diploma_or_graduation_certificate', label: 'Diploma/Graduation certificate' },
  { value: 'course_description', label: 'Course description' },
  { value: 'original_transcript', label: 'Original transcript' },
  { value: 'original_certificate', label: 'Original Certificate' },
  { value: 'transcript_translation', label: 'Original transcript translation' },
  { value: 'verification_from_school', label: 'Verification From School' },
  { value: 'from_nursing_101', label: 'Form 101' },
  { value: 'from_nursing_102', label: 'Form 102' },
  { value: 'from_acupuncture_201', label: 'Form 201' },
  { value: 'from_acupuncture_202', label: 'Form 202' },
]

export const requiredDocsText = [
  { value: 'Diploma/Graduation certificate', label: 'Diploma/Graduation certificate' },
  { value: 'Course description', label: 'Course description' },
  { value: 'Original transcript', label: 'Original transcript' },
  { value: 'Original Certificate', label: 'Original Certificate' },
  { value: 'Original transcript translation', label: 'Original transcript translation' },
  { value: 'Verification From School', label: 'Verification From School' },
  { value: 'Form 101', label: 'Form 101' },
  { value: 'Form 102', label: 'Form 102' },
  { value: 'Form 201', label: 'Form 201' },
  { value: 'Form 202', label: 'Form 202' },
]

export const statusOptionList = [
  // 'Draft Application',
  {
    label: 'Application Submitted',
    value: 'submitted',
    description: 'Application has been successfully submited.',
  },
  // { label: 'Dead Application', value: 'dead_applications', description: '' },
  {
    label: 'Evaluator Assigned',
    value: 'evaluator_assigned',
    description: 'Evaluation has been assigned to the evaluator.',
  },
  {
    label: 'Report In Progess',
    value: 'report_in_process',
    description: 'Report In Progess.',
  },
  {
    label: 'Unofficial Report Ready',
    value: 'unofficial_evaluation_ready',
    description: 'Unofficial Report is ready to be viewed.',
  },
  {
    label: 'Unofficial Report Confirm',
    value: 'unofficial_confirm',
    description: 'The Unofficial Report has been confirmed, and the Official Report will be finalized.',
  },
  {
    label: 'Official Report Ready',
    value: 'official_evaluation_ready',
    description: 'Official Report is ready to be viewed',
  },
  {
    label: 'Offical Report Dispatched',
    value: 'report_dispatched',
    description: 'Official Report has been dispatched.',
  },
  { label: 'Closed', value: 'closed', description: 'Evaluation is completed and closed.' },
  //Fraudulent status to be added conditionally; shouldn't be shown all the time
  { label: 'Fraudulent', value: 'fraudulent', description: 'Fraudulent' },
]

export const changeStatus = [
  { label: 'Report In Progess', value: 'report_in_process' },
  { label: 'Official Evaluation Ready', value: 'official_evaluation_ready' },
  { label: 'Report Dispatched', value: 'report_dispatched' },
  { label: 'Fraudulent', value: 'fraudulent' },
]

export const statusUnofficial = [
  'evaluator_assigned',
  'report_in_process',
  'official_evaluation_ready',
  'unofficial_evaluation_ready',
  'unofficial_confirm',
  'report_dispatched',
  'finished',
  'closed',
]
export const evalCanUpload = [
  'evaluator_assigned',
  'report_in_process',
  'official_evaluation_ready',
  'unofficial_evaluation_ready',
  'unofficial_confirm',
  'report_dispatched',
  'closed',
]
export const statusOfficial = ['evaluator_assigned', 'unofficial_confirm', 'report_in_process']
export const activeStatus = ['dead', 'cancel']

export const statusFinish = ['official_evaluation_ready', 'finished']
export const statusUnofficialView = ['unofficial_evaluation_ready', 'evaluator_assigned', 'report_in_process']
export const evalCanView = [
  'report_in_process',
  'official_evaluation_ready',
  'unofficial_evaluation_ready',
  'unofficial_confirm',
  'report_dispatched',
  'closed',
]
export const adminCanView = [
  'official_evaluation_ready',
  'unofficial_evaluation_ready',
  'unofficial_confirm',
  'report_dispatched',
  'finished',
  'closed',
]
export const clientCanView = [
  'unofficial_confirm',
  'official_evaluation_ready',
  'unofficial_evaluation_ready',
  'report_dispatched',
  'finished',
  'closed',
]

export const resetFilter = ['reset']
