import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button, IconButton, SvgIcon } from '@material-ui/core'
import Bell from '../../components/bell'
import { RouteComponentProps, withRouter } from 'react-router'
// import * as _ from 'lodash'
import { useStoreState, useStoreActions } from '../../store'
import { ArrowDropDown, ArrowDropUp, ExitToAppOutlined } from '@material-ui/icons'
import { ApplicationProgressContext } from '../../common/applicationProgress/ApplicationProgressContext'
import { useNotificationUpdates } from '../../hooks/useNotificationsUpdates'
import NotificationsTooltip from '../../components/profile/notifications/notificationsTooltips'
import { upperFirst } from '../../utils/formatters'
import { baseEresUrl } from '../../utils/getValues'
import MenuIcon from '@material-ui/icons/Menu'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    width: 200,
    '@media screen and (max-width: 991px)': {
      display: 'none',
    },
  },
  bellStyle: {
    marginLeft: 20,
    marginRight: 20,
    '@media screen and (max-width: 575px)': {
      marginLeft: 10,
      marginRight: 10,
    },
  },
  show: {
    width: '100px',
    height: '300px',
    border: '1 px solid black',
  },
  textWrapper: {
    height: 40,
    borderRight: 'solid 0.9px rgba(38, 37, 42, 0.3)',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    '&:focus': {
      outline: '0px solid transparent',
    },
  },
  textStyle: {
    color: 'black',
    fontWeight: 500,
  },
  toggleContainer: {
    position: 'absolute',
    top: 5,
    zIndex: 11,
    padding: '10px',
    background: 'white',
    // width: 180,
    textAlign: 'left',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    marginTop: 40,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 2,
    cursor: 'pointer',
    '&:hover': {
      background: '#f5f5f5',
    },
  },
  logOut: {
    display: 'flex',
  },
  logoutText: {
    color: 'black',
    whiteSpace: 'nowrap',
  },
  requestText: {
    color: 'black',
    whiteSpace: 'nowrap',
    marginBottom: 5,
    display: 'none',
    '@media screen and (max-width: 991px)': {
      display: 'block',
    },
  },
  arrowIcon: {
    color: '#515155',
    marginLeft: 5,
  },
  homeIcon: {
    fontSize: 35,
    '@media screen and (max-width: 575px)': {
      fontSize: 25,
    },
  },
  headerButton: {
    padding: 7,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:last-child': {
      display: 'none',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
    },
  },
})

interface INavBarProps extends RouteComponentProps {}

const NavOptions: React.SFC<INavBarProps> = ({ history }) => {
  const classes = useStyles()
  const notifications = useStoreState((store) => store.notifications.current)
  const currentUser = useStoreState((state) => state.user.current)
  const removeRemoveNotification = useStoreActions((actions) => actions.notifications.removeCurrent)
  const removeCurrentUser = useStoreActions((actions) => actions.user.removeCurrent)
  const removeApplicationSearch = useStoreActions((actions) => actions.application.removeCurrent)
  const removeAdditionalServiceSearch = useStoreActions((actions) => actions.additionalService.removeCurrent)
  const removeUserManagementSearch = useStoreActions((actions) => actions.userManagement.removeCurrent)
  const removeSchoolSearch = useStoreActions((actions) => actions.school.removeCurrent)
  const removeFraudulentManagementSearch = useStoreActions((actions) => actions.fraudulentManagement.removeCurrent)
  const removeDashboardMonthFilter = useStoreActions((actions) => actions.dashboardMonthFilter.removeCurrent)
  const removeDashboardUnassignedFilter = useStoreActions((actions) => actions.dashboardUnassignedFilter.removeCurrent)
  const removeSelectEvaluatorFilter = useStoreActions((actions) => actions.selectedEvaluator.removeCurrent)
  const removeSchoolCountryFilter = useStoreActions((actions) => actions.schoolCountryFilter.removeCurrent)
  const removeDashboardColFilter = useStoreActions((actions) => actions.dashboardColumnFilter.removeCurrent)
  const removeDashboardSort = useStoreActions((actions) => actions.dashboardSort.removeCurrent)
  const removePreEvaluationSort = useStoreActions((actions) => actions.preEvaluationSort.removeCurrent)
  const removeUserManagementSort = useStoreActions((actions) => actions.userManagementSort.removeCurrent)
  const removeSchoolSort = useStoreActions((actions) => actions.schoolSort.removeCurrent)
  const removeFraudulentManagementSort = useStoreActions((actions) => actions.fraudulentManagementSort.removeCurrent)
  const removedraftApplicationSearch = useStoreActions((actions) => actions.draftApplicationSearch.removeCurrent)
  const removedraftApplicationFilter = useStoreActions((actions) => actions.draftApplicationPurposeFilter.removeCurrent)
  const removeAgentFilter = useStoreActions((actions) => actions.agentFilterState.removeCurrent)
  const removedraftApplicationSort = useStoreActions((actions) => actions.draftApplicationSort.removeCurrent)
  const removeApplicationPayementFilter = useStoreActions(
    (actions) => actions.applicationPayementFilterState.removeCurrent
  )
  const removeApplicationEvaluationFilter = useStoreActions(
    (actions) => actions.applicationEvaluationFilterState.removeCurrent
  )
  const removeAdditionalPayementFilter = useStoreActions(
    (actions) => actions.additionalPayementFilterState.removeCurrent
  )
  const removeAdditionalEvaluationFilter = useStoreActions(
    (actions) => actions.additionalEvaluationFilterState.removeCurrent
  )
  const removeApplicaitonEvaluationSearch = useStoreActions(
    (actions) => actions.applicaitonEvaluationSearchState.removeCurrent
  )
  const removeAdditionalEvaluationSearch = useStoreActions(
    (actions) => actions.additionalEvaluationSearchState.removeCurrent
  )
  const removeAppId = useStoreActions((state) => state.applicationIdStore.removeCurrent)
  const setResponsiveState = useStoreActions((actions) => actions.applicationResponsiveState.setCurrent)

  const removeReportedIssueFilter = useStoreActions((actions) => actions.reportedIssueFilter.removeCurrent)
  const [openToggle, setOpenToggle] = React.useState<boolean>(false)
  const { applicationInProgress } = React.useContext(ApplicationProgressContext)

  useNotificationUpdates()

  const handleSubmit = async () => {
    history.push('/application')
  }

  const removeStoreSearchState = () => {
    removeApplicationSearch()
    removeAdditionalServiceSearch()
    removeUserManagementSearch()
    removeSchoolSearch()
    removeFraudulentManagementSearch()
    removeDashboardMonthFilter()
    removeDashboardUnassignedFilter()
    removeSelectEvaluatorFilter()
    removeSchoolCountryFilter()
    removeDashboardColFilter()
    removeDashboardSort()
    removePreEvaluationSort()
    removeUserManagementSort()
    removeSchoolSort()
    removeFraudulentManagementSort()
    removeRemoveNotification()
    removedraftApplicationSearch()
    removedraftApplicationFilter()
    removeAgentFilter()
    removedraftApplicationSort()
    removeApplicationPayementFilter()
    removeApplicationEvaluationFilter()
    removeAdditionalPayementFilter()
    removeAdditionalEvaluationFilter()
    removeApplicaitonEvaluationSearch()
    removeAdditionalEvaluationSearch()
    removeAppId()
    removeReportedIssueFilter()
  }

  const handleLogout = () => {
    removeStoreSearchState()
    removeCurrentUser()
    if (currentUser?.role === 'client') {
      history.push('/sign-in')
    } else if (currentUser?.role === 'agent') {
      history.push('/agent-sign-in')
    } else {
      history.push('/eres-sign-in')
    }
  }

  const handleOpen = React.useCallback(() => {
    setOpenToggle((prevToggle) => !prevToggle)
  }, [setOpenToggle])

  const handleClose = React.useCallback(() => {
    openToggle && setOpenToggle(false) //we close the button when clicked out on  nav
  }, [setOpenToggle, openToggle])
  const applicationResponsiveState = useStoreState(
    (state) => state.applicationResponsiveState.applicationResponsiveState
  )

  const hideApplicationButton = applicationInProgress || (currentUser && ['evaluator'].includes(currentUser.role))

  return (
    <div className={classes.container}>
      {currentUser && (
        <React.Fragment>
          <div className={classes.textWrapper} onClick={handleOpen} onBlur={handleClose} tabIndex={0}>
            <Typography className={classes.textStyle}>
              Hi,{' '}
              {currentUser.first_name
                ? // _.upperFirst(currentUser.first_name)
                upperFirst(currentUser.first_name)
                : currentUser.email}
              !
            </Typography>
            {openToggle ? (
              <ArrowDropUp className={classes.arrowIcon} />
            ) : (
              <ArrowDropDown className={classes.arrowIcon} />
            )}
            {openToggle && (
              <div className={classes.toggleContainer}>
                <div onClick={handleSubmit}>
                  <Typography className={classes.requestText}>Request Evaluation</Typography>
                </div>
                <div onClick={handleLogout} className={classes.logOut}>
                  <Typography className={classes.logoutText}>Log Out</Typography>
                  <ExitToAppOutlined style={{ color: '#bfbfbf', marginLeft: 10 }} />
                </div>
              </div>
            )}
          </div>
          <NotificationsTooltip>
            <div>
              <Bell bellClass={classes.bellStyle} count={notifications?.length} />
            </div>
          </NotificationsTooltip>
          {!hideApplicationButton && (
            <Button
              disabled={false}
              onClick={handleSubmit}
              className={classes.button}
              variant="contained"
              color="primary"
            >
              Request Evaluation
            </Button>
          )}
          <IconButton className={classes.headerButton}>
            <a href={baseEresUrl} className={classes.homeIcon}>
              <SvgIcon color="primary" style={{ fontSize: 35 }}>
                <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
              </SvgIcon>
            </a>
          </IconButton>
          <IconButton className={classes.headerButton} onClick={() => setResponsiveState(!applicationResponsiveState)}>
            <MenuIcon style={{ color: '#526483', marginLeft: 5 }} />
          </IconButton>
        </React.Fragment>
      )}
    </div>
  )
}
export default withRouter(NavOptions)
