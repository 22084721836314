export interface ISelectItem {
  key: number | string
  value: string | number
  name: string
}

export interface IEvaluatorSelectItem extends IOptions {
  commission: string | number
}

export type IOptionsValue = string | number

export interface IOptions {
  value: IOptionsValue
  label: string
  isReversed?: boolean
}

export const getOption = (value: IOptionsValue, label: string): IOptions => {
  return { value: value, label: label }
}

export const emptyOptions: IOptions = {
  value: '',
  label: '',
}
