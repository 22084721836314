import * as Sentry from '@sentry/browser'
import { handleError } from '@jetkit/react'
import { AxiosError } from 'axios'

export const openDownloadUrl = (pdfData: any, filename: string, fileExtension?: string) => {
  //used to properly decode the pdf string so we can read the data and view it in blob

  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var file = new Blob([pdfData])

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file)
    return
  }

  const url = window.URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = url
  if (fileExtension === 'xlsx') {
    link.setAttribute('download', `${filename}.${fileExtension}`)
  } else {
    link.setAttribute('download', `${filename}.pdf`)
  }
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const handleApiErrorWithSentry = (error: AxiosError<any>) => {
  Sentry.captureException(error)
  handleError(error)
}

export const handleErrorWithSentry = (error: AxiosError<any>) => {
  Sentry.captureException(error)
}
