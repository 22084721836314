import * as React from 'react'
import { CountryOptionsListContext } from './CountryOptionsListContext'
import { LanguageOptionsListContext } from './LanguageOptionsListContext'
import { getCountriesList } from '../../services/countryService'
import { getLanguagesList } from '../../services/languagesService'
import { EvaluatorsOptionsListContext } from './EvaluatorsOptionsListContext'
import { OptionsType } from 'react-select'
import { getEvaluators } from '../../services/userService'
import { useStoreState } from '../../store'
import { IEvaluator } from '../../model/User'
import { userRole } from '../../utils/getValues'
interface IGeneralOptionsProviderProps {
  children: React.ReactNode
}

const GeneralOptionsProvider = (props: IGeneralOptionsProviderProps) => {
  const [countries, setCountries] = React.useState<OptionsType>([])
  const [languages, setLanguages] = React.useState<OptionsType>([])
  const [evaluators, setEvaluators] = React.useState<IEvaluator[]>([])
  const currentUser = useStoreState((state) => state.user.current)

  React.useEffect(() => {
    ;(async () => {
      if (!countries?.length) {
        setCountries(await getCountriesList())
      }
      if (!languages?.length) {
        setLanguages(await getLanguagesList())
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  React.useEffect(() => {
    ;(async () => {
      // fetch evaluators if not client user
      currentUser && !userRole.includes(currentUser.role) && setEvaluators(await getEvaluators())
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <EvaluatorsOptionsListContext.Provider value={{ evaluators }}>
      <CountryOptionsListContext.Provider value={{ countries }}>
        <LanguageOptionsListContext.Provider value={{ languages }}>
          {props.children}
        </LanguageOptionsListContext.Provider>
      </CountryOptionsListContext.Provider>
    </EvaluatorsOptionsListContext.Provider>
  )
}

export default GeneralOptionsProvider
