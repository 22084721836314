import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { INotification } from '../../../../model/Notification'
import { Typography, Divider, createStyles, CircularProgress } from '@material-ui/core'
import moment from 'moment'
import { Theme } from '../../../../theme'
import CloseIcon from '@material-ui/icons/Close'
import { removePerticulerNotification } from '../../../../services/notificationsService'
import classNames from 'classnames'
import { DATE_MONTH_YEAR_FORMAT } from '../../../../utils/formatters'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },

    notificationText: {
      fontSize: 12,
      fontWeight: 500,
      color: (props: INotificationItemProps) => (props.variant === 'dark' ? theme.primaryTextColor.color : '#ffffff'),
    },

    timestampBar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 10,
    },

    timestampText: (props) => ({
      fontSize: 12,
      fontWeight: 200,
      color: props.variant === 'dark' ? theme.primaryTextColor.color : '#ffffff',
    }),

    divider: (props) => ({
      marginTop: props.variant === 'dark' ? 20 : 10,
      marginBottom: props.variant === 'dark' ? 20 : 10,
      marginRight: props.variant === 'dark' ? 8 : 0,
      backgroundColor: props.variant === 'dark' ? '#26252a4c' : '#88ffffff',
      opacity: 0.6,
    }),
    icon: {
      color: 'rgba(38, 37, 42, 0.2)',
      cursor: 'pointer',
    },
    progress: {
      margin: 0,
    },
    whiteColor: {
      color: '#fff',
    },
  })
)

export type variant = 'dark' | 'light'

interface INotificationItemProps {
  key: string
  variant: variant
  notification: INotification
  reloadNotification?: () => void
  iconColor: string
}

const NotificationItem = (props: INotificationItemProps) => {
  const classes = useStyles(props)
  const notification = props.notification
  const [isLoading, setIsLoading] = React.useState(false)

  // const hour = React.useMemo(() => {
  //   return moment(notification.created_at_pst).format('h:mm')
  // }, [notification])

  const date = React.useMemo(() => {
    return moment(notification.created_at).format(DATE_MONTH_YEAR_FORMAT)
  }, [notification])

  const handleClose = async () => {
    setIsLoading(true)
    await removePerticulerNotification(notification?.id)
      .then(() => {
        props.reloadNotification()
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  return (
    <div className={classes.root}>
      <div className={classes.timestampBar}>
        <div style={{ width: '85%' }}>
          <Typography className={classes.notificationText}>{notification.message}</Typography>
        </div>
        <div style={{ width: '10%' }}>
          {isLoading ? (
            <CircularProgress
              size={18}
              className={classNames(classes.progress, props.iconColor === '#fff' ? classes.whiteColor : '')}
              color={'inherit'}
            />
          ) : (
            <CloseIcon
              className={classes.icon}
              onClick={handleClose}
              style={{ color: props.iconColor, opacity: 0.5 }}
            />
          )}
        </div>
      </div>
      <div className={classes.timestampBar}>
        <Typography className={classes.timestampText}>{notification?.created_at_pst?.slice(11)}</Typography>
        <Typography className={classes.timestampText}>{date}</Typography>
      </div>
      <Divider className={classes.divider} />
    </div>
  )
}

export default NotificationItem
