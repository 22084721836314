import { Badge } from '@material-ui/core'
import * as React from 'react'
import BellIcon from '../../icons/bellIcon'

interface IBellProps {
  bellClass?: string
  count?: number
}

const Bell: React.FC<IBellProps> = ({ bellClass, count }) => {
  return (
    <span className={bellClass}>
      <Badge color="error" badgeContent={count} max={99}>
        <BellIcon />
      </Badge>
    </span>
  )
}

export default Bell
