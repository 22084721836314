import * as React from 'react'
import { OptionsType } from 'react-select'

interface IEvaluatorsListContext {
  evaluators: OptionsType
}

export const EvaluatorsOptionsListContext = React.createContext<IEvaluatorsListContext>({
  evaluators: [],
})
