import * as React from 'react'
import { ApplicationProgressContext } from './ApplicationProgressContext'

interface IApplicationStatusProviderProps {
  children: React.ReactNode
}

const ApplicationStatusProvider = (props: IApplicationStatusProviderProps) => {
  const [applicationInProgress, setApplicationProgress] = React.useState<boolean>(false)

  const setApplicationInProgress = React.useCallback(() => {
    setApplicationProgress(prevStatus => !prevStatus)
  }, [])

  return (
    <ApplicationProgressContext.Provider value={{ applicationInProgress, setApplicationInProgress }}>
      {props.children}
    </ApplicationProgressContext.Provider>
  )
}

export default ApplicationStatusProvider
