import React from 'react'
import { useStoreActions, useStoreState } from '../store'
import { getNotifications } from '../services/notificationsService'
import { handleApiErrorWithSentry } from '../services/common'

const HOUR = 1000 * 60 * 60

export function useNotificationUpdates() {
  const setCurrentNotifications = useStoreActions((actions) => actions.notifications.setCurrent)
  const currentUser = useStoreState((state) => state.user.current)

  const fetchNotifications = React.useCallback(async () => {
    try {
      const notifications = await getNotifications()
      setCurrentNotifications(notifications)
    } catch (error) {
      handleApiErrorWithSentry(error)
    }
  }, [setCurrentNotifications])

  const pollForNotifications = React.useCallback(async (): Promise<number> => {
    return window.setInterval(() => {
      fetchNotifications() // poll
    }, HOUR)
  }, [fetchNotifications])

  React.useEffect(() => {
    let timerId
    if (currentUser?.id) {
      pollForNotifications().then((_timerId) => (timerId = _timerId))
      fetchNotifications()
    }
    return () => {
      if (timerId) window.clearInterval(timerId)
    }
  }, [pollForNotifications, fetchNotifications, currentUser?.id])
}
